import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'

// third-party
import { FormattedMessage } from 'react-intl'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'
import useAuth from 'hooks/useAuth'
import { RxCross1 } from 'react-icons/rx'

// assets
import { IconLogout } from '@tabler/icons'
import useConfig from 'hooks/useConfig'
import Cookies from 'universal-cookie'
import { persister, useSelector } from 'store'
import axios from 'axios'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import LoadBalanceDialog from '../../../../ui-component/LoadBalance'
import {
  GetBalance,
  HideAllNotifications,
  HideNotification,
  ReadNotifications,
} from 'store/slices/user'
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { FaAngleDown } from 'react-icons/fa6'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)

const timeAgo = new TimeAgo('en-US')

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme()
  const { borderRadius } = useConfig()
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { logout, user, switchUser } = useAuth()
  const auth = useAuth()
  const teamMembers = useSelector(state => state.user.teamMembers)
  const userState = useSelector(state => state.user)
  const balance = useSelector(state => state.user.balance)
  const connection = useSelector(state => state.user.connection)

  useState(() => {
    auth.firebaseGetToken(token => {
      GetBalance(token, userState)
    })
  }, [])

  const handleLogout = async () => {
    try {
      await logout()
      cookies.remove('completedSetup')
      persister.purge()
      navigate('/login')
    } catch (err) {
      console.error(err)
    }
  }

  const handleUserSwitch = async uid => {
    const data = new FormData()
    data.append('uid', uid)
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/user/switch-user',
      data,
      {
        headers: { authorization: await auth.firebaseGetToken() },
      },
    )
    await switchUser(resp.data)
  }

  // Team Profile Selection
  const [profileSelectedIndex, setProfileSelectedIndex] = useState(-1)
  const [openProfile, setOpenProfile] = useState(false)
  const profileAnchorRef = useRef(null)
  const prevProfileOpen = useRef(openProfile)

  const handleProfileClose = event => {
    if (
      profileAnchorRef.current &&
      profileAnchorRef.current.contains(event.target)
    ) {
      return
    }
    setOpenProfile(false)
  }

  const handleProfileToggle = () => {
    setOpenProfile(prevProfileOpen => !prevProfileOpen)
  }

  useEffect(() => {
    if (prevProfileOpen.current === true && openProfile === false) {
      profileAnchorRef.current.focus()
    }

    prevProfileOpen.current = openProfile
  }, [openProfile])

  // Account settings
  const [settingSelectedIndex, setSettingSelectedIndex] = useState(-1)
  const [openSettings, setOpenSettings] = useState(false)
  const settingAnchorRef = useRef(null)
  const prevSettingOpen = useRef(openSettings)

  const handleSettingClose = event => {
    if (
      settingAnchorRef.current &&
      settingAnchorRef.current.contains(event.target)
    ) {
      return
    }
    setOpenSettings(false)
  }

  useEffect(() => {
    if (prevSettingOpen.current === true && openSettings === false) {
      settingAnchorRef.current.focus()
    }

    prevSettingOpen.current = openSettings
  }, [openSettings])

  const [openBalanceDialog, setOpenBalanceDialog] = useState(false)
  const [openNotificationWall, setOpenNotificationWall] = useState(false)

  const NotificationWall = () => (
    <List sx={{ width: '300px' }}>
      <ListSubheader mb={2}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography color='black'>Notifications</Typography>
          <Button
            onClick={() => {
              auth.firebaseGetToken().then(token => {
                HideAllNotifications(token)
              })
            }}
            color='primary'
            size='small'
          >
            <Typography lineHeight='20px' variant='overline'>
              Clear All
            </Typography>
          </Button>
        </Stack>
      </ListSubheader>
      {/* LINKEDIN SCREAMING BUTTON - CONNECTION CHECK */}
      {connection !== 'CONNECTED' && connection !== 'TRANSITION' && (
        <ListItem>
          <Link to={`/settings/linkedin`}>
            <Button
              size='small'
              variant='contained'
              color='error'
              startIcon={<ErrorOutlineRoundedIcon />}
              sx={{
                borderRadius: '5px',
                textTransform: 'none',
                animation: 'pulse 1.25s infinite',
              }}
            >
              Connection to your LinkedIn account was lost
            </Button>
          </Link>
        </ListItem>
      )}
      {userState.notifications.map((item, i) => (
        <ListItem
          secondaryAction={
            <IconButton
              onClick={() => {
                auth.firebaseGetToken().then(token => {
                  HideNotification(token, item.id)
                })
              }}
              edge='end'
            >
              <RxCross1 size='15px' />
            </IconButton>
          }
        >
          <ListItemText
            primary={
              <Typography>
                <FiberManualRecordIcon
                  fontSize='13px'
                  sx={{ mr: 0.5, mt: '2px' }}
                  color={item.variant}
                />
                {item.message}{' '}
                <Typography display='inline' variant='caption' align='left'>
                  {timeAgo.format(
                    new Date(item.timestamp),
                    'twitter-minute-now',
                  )}
                </Typography>
              </Typography>
            }
          />
        </ListItem>
      ))}
      {connection === 'CONNECTED' &&
        connection === 'TRANSITION' &&
        userState.notifications.length === 0 && (
          <ListItem>
            <Typography>No new notification to show</Typography>
          </ListItem>
        )}
    </List>
  )

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {/* LINKEDIN SCREAMING BUTTON - CONNECTION CHECK */}
      {connection !== 'CONNECTED' && connection !== 'TRANSITION' && (
        <Link to={`/settings/linkedin`}>
          <Button
            size='small'
            variant='contained'
            color='error'
            startIcon={<ErrorOutlineRoundedIcon />}
            sx={{
              borderRadius: '5px',
              textTransform: 'none',
              animation: 'pulse 1.25s infinite',
            }}
          >
            Connection to your LinkedIn account was lost
          </Button>
        </Link>
      )}
      <IconButton
        onClick={() => {
          setOpenNotificationWall(true)
        }}
        size='small'
      >
        <Badge
          variant='dot'
          color='error'
          // badgeContent={connection !== "CONNECTED" || connection !== "TRANSITION" || userState.notifications.filter(item => item.not_read).length > 0 ? 1 : 0}
          badgeContent={
            userState.notifications?.filter(item => item.not_read).length > 0
              ? 1
              : 0
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <NotificationsNoneTwoToneIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor='right'
        open={openNotificationWall}
        onClose={() => {
          // MARK AS READ
          auth.firebaseGetToken().then(token => {
            ReadNotifications(token)
          })
          // CLOSE DRAWER
          setOpenNotificationWall(false)
        }}
      >
        <NotificationWall />
      </Drawer>
      {userState.maple.id && (
        <Button
          size='small'
          sx={{
            borderRadius: '27px',
            boxShadow: 'none',
            bgcolor: theme.palette.josh.purple,
            color: 'white',
          }}
          variant='contained'
          elevation={0}
          onClick={() => {
            setOpenBalanceDialog(true)
          }}
          startIcon={<MonetizationOnRoundedIcon />}
          endIcon={<FaAngleDown size='12px' />}
        >
          <Typography color='white'>{balance ? balance : 0} Credits</Typography>
        </Button>
      )}
      {/* { userState.maple.id && (
          <Chip size="small" color="primary" 
            icon={<MonetizationOnRoundedIcon />} 
            label={<Typography color="white">{balance ? balance : 0} Credits</Typography>} />
      )} */}
      {/* PROFILE SWITCHING + MAPLE BALANCE */}
      <Button
        size='small'
        sx={{
          // height: "30px",
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            // "& svg": {
            //   stroke: theme.palette.primary.light,
            // },
            // "& .MuiChip-root ": {
            //   bgcolor: "white",
            //   color: theme.palette.primary.main,
            //   "&. MuiTypography-root": {
            //     color: "white"
            //   }
            // },
          },
        }}
        color='inherit'
        variant='outlined'
        ref={profileAnchorRef}
        aria-controls={openProfile ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleProfileToggle}
        endIcon={<FaAngleDown size='12px' />}
        color='primary'
      >
        {user?.name}
      </Button>
      <Popper
        placement='bottom'
        open={openProfile}
        anchorEl={profileAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 1],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleProfileClose}>
            <Transitions in={openProfile} {...TransitionProps}>
              <Paper>
                {openProfile && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ px: 1 }}>
                      <List
                        component='nav'
                        sx={{
                          width: '100%',
                          maxWidth: 300,
                          minWidth: 200,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%',
                          },
                          '& .MuiListItemButton-root': {
                            my: 1,
                          },
                        }}
                      >
                        <ListItemText>
                          <Stack
                            direction='row'
                            spacing={0.5}
                            alignItems='center'
                          >
                            {teamMembers.length > 1 && (
                              <Typography
                                component='span'
                                variant='h5'
                                sx={{ fontWeight: 400 }}
                              >
                                Switch to a different account
                              </Typography>
                            )}
                          </Stack>
                        </ListItemText>
                        {teamMembers?.map((item, i) => {
                          if (i !== 0) {
                            return (
                              <ListItemButton
                                className='teammember'
                                color='primary'
                                sx={{
                                  borderRadius: `${borderRadius}px`,
                                  height: '30px',
                                  '&:hover': {
                                    backgroundColor:
                                      theme.palette.primary.light,
                                    color: 'white',
                                    '& .MuiListItemIcon-root': {
                                      color: 'white',
                                    },
                                  },
                                }}
                                selected={profileSelectedIndex === i}
                                onClick={() => {
                                  handleUserSwitch(item.id)
                                }}
                              >
                                <ListItemIcon>
                                  <Avatar
                                    src={item.name}
                                    alt={item.name}
                                    sx={{
                                      ...theme.typography.mediumAvatar,
                                      margin: '8px 2px 8px 0px !important',
                                      cursor: 'pointer',
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant='body2'>
                                      <FormattedMessage id={item.name} />
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            )
                          }
                        })}
                        <Divider />
                        {/* { userState.maple.id && (
                          <ListItemButton color="primary"
                            sx={{
                              borderRadius: `${borderRadius}px`, height: "30px",
                              "&:hover": {
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.main,
                                "& .MuiListItemIcon-root": {
                                  color: theme.palette.primary.main,
                                } 
                              }
                            }}
                            selected={settingSelectedIndex === 100}
                            onClick={() => {setOpenBalanceDialog(true)}}
                          >
                            <ListItemIcon>
                              <MonetizationOnRoundedIcon sx={{color: amber[700]}} stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Load Balance
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )} */}
                        <ListItemButton
                          color='primary'
                          sx={{
                            borderRadius: `${borderRadius}px`,
                            height: '30px',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.light,
                              color: theme.palette.primary.main,
                              '& .MuiListItemIcon-root': {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                          selected={settingSelectedIndex === 100}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size='20px' />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant='body2'>
                                <FormattedMessage id='logout' />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
      <Dialog
        open={openBalanceDialog}
        onClose={() => {
          setOpenBalanceDialog(false)
        }}
        fullWidth
        maxWidth='sm'
        sx={{ p: 1 }}
      >
        <LoadBalanceDialog />
      </Dialog>
    </Stack>
  )
}

export default ProfileSection
